import { useClassNames } from '@metaforcelabs/metaforce-core';
import React, { useContext, useEffect, useState } from 'react';
import { WorkflowContext, WorkflowStepContext, WorkflowValuesContext } from '../../../../contexts';
import { usePreviousSteps } from '../../../../hooks/usePreviousSteps';
import { workflowAppType } from '../../../../utils/constants';
import { getPlaceHolders } from '../../../../utils/slate';
import { stepValidator } from '../stepValidator';
import { convertValuesToArray } from '../utils';
import AppEventList from './appEventList';
import AppEventSelect from './appEventSelect';
import NextStepArrow from './nextStepArrow';
import SetupAction from './setupAction';
import WorkflowItemHeader from './workflowItemHeader';
import { toast } from 'react-hot-toast';
import { getStepValues } from '../../../../api/workflowDefinition';
import AccountSelect from './AccountSelect';
import { useWorkflowStep } from '../../../../hooks/useWorkflowStep';
import DndDragHandle from '../../../../components/DragAndDrop/dndDragHandle';

const WorkFlowStep = ({ workflowStep, stepCount, stepIdx = 0, activeStep, isDragOverlayComponent = false }) => {

    const [activeStepSection, setActiveStepSection] = useState(0);
    const { classNames } = useClassNames();
    const { workflowStepValues, workFlow: workflow, testValues, mapDialogElementsToWorkflowElement, extractPlaceholdersFromObject, ...workFlowContext } = useContext(WorkflowContext);
    const { workFlowValues } = useContext(WorkflowValuesContext);
    const { filterPreviousSteps } = usePreviousSteps(workflow.workflowSteps, workflow.workflowBranches);
    const [previousSteps, setPreviousSteps] = useState([]);
    const [previousStepsValues, setPreviousStepsValues] = useState([]);
    const [inUsePlaceholders, setInUsePlaceholders] = useState([]);
    const [isInitialized, setIsInitialized] = useState(false);
    const { canCopy, canMove } = useWorkflowStep();

    const isLastStep = stepCount - 1 === stepIdx
    const handleAppSelect = (appEvent) => {
        workflowStep.name = appEvent.name;
        workflowStep.appEvent = appEvent;
        workFlowContext.updateWorkflowStep(workflowStep, workflowStep.id)
    }

    const handleAddNextStep = () => {
        workFlowContext.addStep(workflowStep.id, workflowStep.branchId);
    }

    const validateStep = (values) => {
        const errors = stepValidator(workflowStep, workFlowValues, values, workflow.workflowBranches, workflow?.workflowSteps || [], workFlowContext?.validationErrors || []);
        // console.log(errors)
        workFlowContext.setValidationError(workflowStep.id, errors, isInitialized);
        handleValidationErrorAfterDragAndDrop(errors);
    }

    const handleValidationErrorAfterDragAndDrop = (errors) => {
        const wfStepIndex = workFlowContext.dragAndDropChangedStepsRef.current.findIndex(ws => ws === workflowStep.id);
        if (wfStepIndex !== -1) {
            const currErrors = workFlowContext.validationErrors[workflowStep.id];
            if (JSON.stringify(currErrors) !== JSON.stringify(errors)) {
                if (Array.isArray(errors) && errors.find(e => e.error === "Placeholders missing ref")) {
                    toast.error(`Variables are no longer valid in the step '${stepIdx + 1}. ${workflowStep?.name}'.`);
                }
            }
            workFlowContext.dragAndDropChangedStepsRef.current.splice(wfStepIndex, 1);
        }
    }

    const handleWorkflowChange = async () => {
        const steps = filterPreviousSteps(workflowStep.id).map(({ appEvent, id, name, selectedEvent }, i) => {
            const stepValue = workflowStepValues.find(x => x.workflowStepId === id);

            return { appEvent, selectedEvent, id, name, stepIdx: i, values: stepValue?.values || [] }
        });

        const thisStepValue = workflowStepValues.find(x => x.workflowStepId === workflowStep.id);

        setPreviousSteps(steps);
        const stepPlaceHolders = thisStepValue?.stepPlaceHolders || [];
        setInUsePlaceholders(prev => stepPlaceHolders.filter((x, i, self) => self.findIndex(s => s.id === x.id) === i));
        const prevStepsValues = steps.map(step => {
            return step.values.map(v => ({
                name: v.value.name,
                isArray: v.value.isArray,
                id: v.key,
                stepIdx: step.stepIdx,
                stepName: step.name,
                stepId: step.id,
                icon: step.appEvent.appIcon
            }));
        });

        setPreviousStepsValues(prevStepsValues);
        validateStep(prevStepsValues);

    }

    const isBranchTypeStep = () => {
        return workflowStep?.appEvent?.type === workflowAppType.branch || workflowStep?.appEvent?.type === workflowAppType.distribution || workflowStep?.appEvent?.type === workflowAppType.loop;
    }

    useEffect(() => {

        var s = workflowStep;
        var b = workflowStepValues;
        // debugger;

    }, [])


    useEffect(() => {
        validateStep(previousStepsValues);
        if (!isInitialized) {
            setIsInitialized(true);
        }

    }, [workflow, workFlowValues]);

    useEffect(() => {
        //console.log(workflowStepValues)
        handleWorkflowChange();
    }, [workflowStepValues]);

    return (
        <WorkflowStepContext.Provider value={{
            previousSteps: previousSteps,
            previousStepsValues: previousStepsValues,
            stepTestValues: testValues[workflowStep.id] || {},
            inUsePlaceholders,
            stepIdx: stepIdx,
            isDragOverlayComponent: isDragOverlayComponent,
        }}>
            <div className={classNames("max-w-4xl relative group", isLastStep ? "mb-12" : "")}>
                <div className={classNames("rounded-lg border shadow-lg divide-y divide-gray-200",
                    activeStep ? "" : "cursor-pointer opacity-70 hover:opacity-100"
                )}
                    onClick={e => {
                        if (!activeStep) {
                            workFlowContext.setActiveWorkflowStep(workflowStep.id);
                        }
                    }

                    }
                >
                    <WorkflowItemHeader workflowStep={workflowStep} stepIdx={stepIdx} activeStep={activeStep} />
                    <div className="bg-white">
                        <div className='divide-y divide-gray-200'>
                            {
                                activeStep && (
                                    workflowStep.appEvent ? (
                                        <>
                                            <AppEventSelect setupStepIdx={0} activeStepSection={activeStepSection} setActiveStep={setActiveStepSection} workflowStep={workflowStep} />
                                            {
                                                workflowStep.selectedEvent?.useAccountSelect && (
                                                    <AccountSelect setupStepIdx={1} activeStepSection={activeStepSection} setActiveStep={setActiveStepSection} workflowStep={workflowStep} />
                                                )
                                            }
                                            <SetupAction setupStepIdx={workflowStep.selectedEvent?.useAccountSelect ? 2 : 1} activeStepSection={activeStepSection} setActiveStep={setActiveStepSection} workflowStep={workflowStep} workflowStepIdx={stepIdx} />
                                        </>
                                    )
                                        : (
                                            //TODO: Add non appevent list
                                            <div className="py-4 px-6">
                                                <AppEventList onAppSelect={e => handleAppSelect(e)} workflowStepId={workflowStep.id} />
                                            </div>
                                        ))

                            }
                        </div>
                    </div>
                </div>
                {
                    (stepIdx < stepCount - 1 || (stepCount > 0 && workflowStep.appEvent)) && !isBranchTypeStep() && !isDragOverlayComponent && (
                        <NextStepArrow isLastStep={isLastStep} onAddStep={() => handleAddNextStep()} />
                    )
                }
                {
                    canMove(workflowStep) && (
                        <div className='absolute top-10 -left-5 md:-left-7 text-transparent hover:text-gray-600 group-hover:text-gray-600'>
                            <DndDragHandle
                                workflowStep={workflowStep}
                                isDragOverlayComponent={isDragOverlayComponent}
                                disabled={workFlowContext.dragAndDropDisabled}
                            />
                        </div>
                    )
                }

            </div>
        </WorkflowStepContext.Provider>
    )
}

export default WorkFlowStep;